.ermsc {
  --rmsc-main: #4285f4;
  --ermsc-hover: #f1f3f5;
  --ermsc-selected: #e2e6ea;
  --ermsc-border: #ccc;
  --ermsc-gray: #aaa;
  --ermsc-bg: #fff;
  --ermsc-p: 10px;
  --ermsc-radius: 4px;
  --ermsc-h: 38px;
}

.ermsc * {
  box-sizing: border-box;
  transition: all 0.2s ease;
}

.ermsc .gray {
  color: var(--ermsc-gray);
}

.ermsc .dropdown-content {
  position: absolute;
  z-index: 100;
  top: 100%;
  width: 100%;
  padding-top: 8px;
}

.ermsc .dropdown-content .panel-content {
  overflow: hidden;
  border-radius: var(--ermsc-radius);
  background: var(--ermsc-bg);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 11px rgba(0, 0, 0, 0.1);
}

.ermsc .dropdown-container {
  position: relative;
  outline: 0;
  background-color: var(--ermsc-bg);
  border: 1px solid var(--ermsc-border);
  border-radius: var(--ermsc-radius);
}

.ermsc .dropdown-container[aria-disabled="true"]:focus-within {
  box-shadow: var(--ermsc-gray) 0 0 0 1px;
  border-color: var(--ermsc-gray);
}

.ermsc .dropdown-container:focus-within {
  box-shadow: var(--ermsc-main) 0 0 0 1px;
  border-color: var(--ermsc-main);
}

.ermsc .dropdown-heading {
  position: relative;
  padding: 0 var(--ermsc-p);
  display: flex;
  align-items: center;
  width: 100%;
  height: var(--ermsc-h);
  cursor: default;
  outline: 0;
}

.ermsc .dropdown-heading .dropdown-heading-value {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
}

.ermsc .clear-selected-button {
  cursor: pointer;
  background: none;
  border: 0;
  padding: 0;
  display: flex;
}

.ermsc .options {
  max-height: 260px;
  overflow-y: auto;
  margin: 0;
  padding-left: 0;
}

.ermsc .options li {
  list-style: none;
  margin: 0;
}

.ermsc .select-item {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  padding: var(--ermsc-p);
  outline: 0;
}

.ermsc .select-item:hover,
.ermsc .select-item:focus {
  background: var(--ermsc-hover);
}

.ermsc .select-item.selected {
  background: var(--ermsc-selected);
}

.ermsc .no-options {
  padding: var(--ermsc-p);
  text-align: center;
  color: var(--ermsc-gray);
}

.ermsc .search {
  width: 100%;
  position: relative;
  border-bottom: 1px solid var(--ermsc-border);
}

.ermsc .search input {
  background: none;
  height: var(--ermsc-h);
  padding: 0 var(--ermsc-p);
  width: 100%;
  outline: 0;
  border: 0;
  color: white;
}

.ermsc .search-clear-button {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background: none;
  border: 0;
  padding: 0 calc(var(--ermsc-p) / 2);
}

.ermsc .search-clear-button [hidden] {
  display: none;
}

.ermsc .item-renderer {
  display: flex;
  align-items: baseline;
}

.ermsc .item-renderer input {
  margin: 0;
  margin-right: 5px;
}

.ermsc .item-renderer.disabled {
  opacity: 0.5;
}

.ermsc .spinner {
  animation: rotate 2s linear infinite;
}

.ermsc .spinner .path {
  stroke: var(--ermsc-border);
  stroke-width: 4px;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.loader {
  border: 12px solid #f3f3f3;
  border-radius: 80%;
  border-top: 12px solid #ff8d72;
  width: 13px;
  height: 13px;
  /*-webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 0.5s linear infinite;
  margin: auto !important;
  
}

.loaderDiv {
  padding: 0.5em !important;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
