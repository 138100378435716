.rmsc {
  --rmsc-main: #4285f4 !important;
  --rmsc-hover: #6c757d !important;
  --rmsc-selected: #6c757d !important;
  --rmsc-border: #ccc !important;
  --rmsc-gray: #aaa !important;
  --rmsc-bg: #1e1e2f !important;
  --rmsc-p: 10px; /* Spacing */
  --rmsc-radius: 4px; /* Radius */
  --rmsc-h: 38px; /* Height */
}

.ermsc {
  --ermsc-main: #4285f4 !important;
  --ermsc-hover: #6c757d !important;
  --ermsc-selected: #6c757d !important;
  --ermsc-border: #ccc !important;
  --ermsc-gray: #aaa !important;
  --ermsc-bg: #1e1e2f !important;
  --ermsc-p: 10px; /* Spacing */
  --ermsc-radius: 4px; /* Radius */
  --ermsc-h: 38px; /* Height */
}

/*this changes the calendar icon for the datetime filter
from black to white  */
::-webkit-calendar-picker-indicator {
  filter: invert(1);
}