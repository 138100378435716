.rmsc .dropdown-content {
  position: absolute;
  /* zIndex of 11 is just above the table header*/
  z-index: 11 !important;
  top: 100%;
  width: 100%;
  padding-top: 8px;
}

.rmsc .search {
  background-color: white;
}
